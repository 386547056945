import React from 'react';

import imageIllustrationSecurityWeb from '../../assets/images/illustrations/Security-web.png';

import CustomHeadingSection from '../../components/CommonSections/CustomHeadingSection';
import SectionWrapper from '../../components/CommonSections/SectionWrapper';
import { SecondaryTitle } from '../../components/Typography';

import CustomImage from '../../components/Image/Image';

const SixthSection = ({ isMobile }) => (
  <section
    id="trust-and-security"
    // id={!isMobile ? 'trust-and-security' : 'trust-and-security--off'}
    className="sixth-section"
  >
    {/* <div className="container">
        <div className="row">
          <div
            // id={isMobile ? 'trust-and-security' : 'trust-and-security--off'}
            className="order-1 order-md-0 col-md-7 col-lg-6 content"
          >
            <div className="title-section">We take your security and privacy extremely seriously</div>
            <p className="paragraph">
              We use bank level security and never share your personal data without your consent.
            </p>
            <div className="title-section">FCA Registered</div>
            <p className="paragraph">
              We're an appointed representative of Connect IFA Ltd for mortgage services and
              Prepay Technologies Ltd for the issuing of electronic money.
            </p>
          </div>
          <div className="col-md-4 offset-lg-1 image">
            <CustomImage className="w-100" src={imageIllustrationSecurityWeb} alt="security" />
          </div>
        </div>
      </div> */}
    <SectionWrapper>
      <CustomHeadingSection
        leftContent={
          <div>
            <SecondaryTitle>We take your security extremely seriously</SecondaryTitle>
            <p className="paragraph">
              We have bank level equivalent security in place and use 256-bit TLS encryption to keep
              you safe.
            </p>
            <div className="content__container">
              <div className='text-container'>
                <SecondaryTitle>
                  <span>Data privacy</span>
                </SecondaryTitle>
                <p className="paragraph">
                  We never share your personal data with any other parties without your consent.
                </p>
              </div>
              {isMobile ? (
                <div className="image-container">
                  <CustomImage
                    className={isMobile ? null : 'w-100'}
                    src={imageIllustrationSecurityWeb}
                    alt="security"
                  />
                </div>
              ) : null}
            </div>
          </div>
        }
        rightContent={
          !isMobile ? (
            <div className="image-container">
              <CustomImage
                className={isMobile ? null : 'w-100'}
                src={imageIllustrationSecurityWeb}
                alt="security"
              />
            </div>
          ) : null
        }
        leftSize={6}
        rightSize={6}
      />
    </SectionWrapper>
  </section>
);

export default SixthSection;
